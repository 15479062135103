import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


class ExperienceCard extends React.Component {
  state = {
    width: ''
  };
  componentDidMount() {
    this.setState({
      width: window.innerWidth
    });
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width < 767;
    const isDesktop = width >= 768;

    return (
      <div className="experience__card" id={this.props.id ? this.props.id : ''}>
        <div className={this.props.rowType}>
          <div className="col-4">
            <div className="experience__card__image">
              {isDesktop && <LazyLoadImage effect="blur" src={this.props.image} alt={this.props.imageAlt} />}
              {isMobile && (this.props.mobileImage && <LazyLoadImage effect="blur" src={this.props.mobileImage} alt={this.props.mobileImageAlt} />) }
              {isMobile && !this.props.mobileImage && <LazyLoadImage effect="blur" src={this.props.image} alt={this.props.imageAlt} />}
            </div>
          </div>
          <div className="col-8">
            <div className="experience__card__content">
              {this.props.header && <h3>{this.props.header}</h3>}
              {this.props.subheader && <h4>{this.props.subheader}</h4>}
              {this.props.text && <p>{this.props.text}</p>}
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ExperienceCard;
