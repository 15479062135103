import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';

const BmwPcLocations = props => {
  return (
    <section className="bmw-locations">
      <div className="container">
        <div className="performance-center-locations">
          <h3>BMW PERFORMANCE CENTER LOCATIONS AT A GLANCE</h3>
          <div className="row">
            <div className="col">
              <div>
                <h5>How many BMW U.S. Rider Academy locations are there?</h5>
                <p>We currently have one location in South Carolina.</p>
              </div>
              <div>
                <h5>BMW Performance Center</h5>
                <p>1155 SC-101, Greer, SC 29651<br/> (888) 345-4269</p>
              </div>
              <div>
                <h5>Is the BMW Performance Center affiliated with area hotels?</h5>
                <p>Please visit our <a href="/hotelpartners">Hotels Page</a> for more information.</p>
              </div>
            </div>
            <div className="col">
              <div>
                <h5>Can I pick up a new BMW Motorcycle at the BMW Performance Center?</h5>
                <p>Currently, we only offer new car delivery.</p>
              </div>
              <div>
                <h5>Does the BMW U.S. Rider Academy offer classes at other locations?</h5>
                <p>We currently only feature classes at our South Carolina location.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BmwPcLocations;