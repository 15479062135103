import React from 'react';

import Layout from '../../components/motorrad/layout';
import SEO from '../../components/motorrad/seo';

//components
import HomeVideoBackground from '../../components/homevideobackground';
import ExperienceCard from '../../components/experiencecard';
import LocationCard from '../../components/locationcard';
import settings from '../../../settings';
import BmwPcLocations from '../../components/motorrad/bmwPcLocations';

const pavement = `${settings.IMAGES_BASE_URL}/images/motorrad/locations/on-road-off-oad-track.jpg`;
const SouthCarolina = `${settings.IMAGES_BASE_URL}/images/motorrad/locations/south-carolina-blue-ridge-mountians.jpg`;
const Zentrum = `${settings.IMAGES_BASE_URL}/images/motorrad/locations/bmw-zentrum-south-carolina.jpg`;
const autofactory = `${settings.IMAGES_BASE_URL}/images/motorrad/locations/bmw-factory-tour.jpg`;
const GreenvilleMobile = `${settings.IMAGES_BASE_URL}/images/locations/bmw-spartanburg-building.jpg`
const videoUrl = `${settings.IMAGES_BASE_URL}/videos/moto-locations.mp4`;

const DriverSchoolPage = () => (
  <Layout>
    <SEO title="The BMW U.S. Rider Academy | Motorrad Locations" description="With pavement or without? Your choice of one-of-a-kind experiences, plus more info about our Zentrum Museum and auto factory tours." />
    <HomeVideoBackground
      headerTitle="a ride like no other "
      url={videoUrl}
      poster={pavement}
      mobileImage={SouthCarolina}
      noAudio="true"
    />
    <section className="location">
      <div className="container">
        <div className="location__header">
          <h1>LOCATIONS AND AMENITIES</h1>
          <p>
          South Carolina offers beautiful surroundings, charming towns and plenty of riding pleasure. Great roads winding through the Blue Ridge Mountains, including the iconic Tail of the Dragon, mean you’ll experience one-of-a-kind rides.
          </p>
        </div>
      </div>
      <div className="location__type">
        <ExperienceCard
          rowType="row"
          image={SouthCarolina}
          mobileImage={GreenvilleMobile}
          imageAlt="South Carolina Blue Ridge Mountains"
          header={["Greenville - Spartanburg,", <br />, "South Carolina"]}
          text={["Make a vacation out of your visit to the Performance Center in Greenville-Spartanburg. With numerous hotels, nearby golf courses and charming downtown Greenville a short drive away, there is plenty to explore when not behind the bars of an Ultimate Riding Machine", <sup key="101">®</sup>, "."]}
        >
          <div className="buttons">
            <a href="https://www.visitgreenvillesc.com/" target="_blank" rel="noopener noreferrer"><button className="btn btn-bordered">Learn More about greenville</button></a>
            <a href="https://www.visitspartanburg.com/" target="_blank" rel="noopener noreferrer"><button className="btn btn-bordered">Learn More about spartanburg</button></a>
          </div>
        </ExperienceCard>
        <ExperienceCard
          rowType="row-reverse"
          image={pavement}
          imageAlt="Spartanburg Overhead Track"
          header={["With pavement,", <br /> , "or without?"]}
          text="Whether on-road or off, we’ve got terrain covered. On-road classes use our Performance School track, and off-road has a variety of obstacles like gravel, hills, sand and of course mud."
       />

        <div className="location__cards">
          <div className="container">
            <div className="row">
              <LocationCard
                image={Zentrum}
                imageAlt="The Zentrum"
                title="THE ZENTRUM"
                text="Explore BMW’s past while marveling at its future  with a tour of the Zentrum Museum. Featuring many of BMW’s most famous models, you’ll get up close with our history and what makes a BMW so special. Open daily from 9 AM to 5:30 PM."
                btnText="Find out More"
                url="https://www.bmwgroup-plants.com/spartanburg/en/plant-tours-zentrum-museum.html"
              />
              <LocationCard
                image={autofactory}
                imageAlt="FACTORY TOUR"
                title="AUTO FACTORY TOUR"
                text="Located just across the street from the Performance Center is our manufacturing plant for the X3, X4, X5, X6 and X7. Schedule a tour and watch in amazement as over 1,500 vehicles are produced each day. Please visit the plant website for more information."
                btnText="Find out More"
                url="https://www.bmwgroup-plants.com/spartanburg/en/plant-tours-zentrum-museum.html"
              />
            </div>
          </div>
        </div>
      </div>
      <BmwPcLocations/>
    </section>
  </Layout>
);

export default DriverSchoolPage;
