import React from "react";

const MakeLifeARide = (props) => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 810 142"
        height={30}
        {...props}
    >
        <defs>
            <style>{".cls-1{fill:#12120d}"}</style>
        </defs>
        <g id="Logo">
            <path className="cls-1" d="M0 125h810v17H0z"/>
            <path
                className="cls-1"
                d="m551.7 129-1.3-37.3c-.9 3.3-1.8 6.7-2.9 10.1l-8.8 27.2h-14.3l-8.8-27.2c-1.1-3.3-2-6.6-2.8-9.9l-1.3 37.1h-14.2l2.3-56H520l11.6 38.8L543.2 73h20.4l2.3 56ZM609.996 118h-21.092c-.908 3.633-1.917 7.266-2.826 11H571.2c5-20.4 10.7-38.5 18.2-56h20.3a340.31 340.31 0 0 1 18.1 56h-14.978c-.909-3.734-1.817-7.367-2.826-11Zm-3.696-13c-2.2-7.8-4.6-15-6.8-20.8-2.4 5.8-4.6 13.1-6.9 20.8ZM676.285 109.1A133.927 133.927 0 0 1 687 128.8v.2h-16.356a100.678 100.678 0 0 0-6.443-11.5 93.795 93.795 0 0 0-7.55-9.8L649 116v13h-15V73h15v24.4L669.836 73h18.057l-21.53 23.8a100.969 100.969 0 0 1 9.922 12.3ZM708 86v9h27v12h-26v9h28v13h-43V73h43v13h-29ZM802 116v13h-37V73h15v43ZM809 73h15v56h-15ZM850 86v10h25v12h-25v21h-15V73h40v13h-25ZM900 86v9h26v12h-26v9h28v13h-43V73h43v13h-28ZM990.196 118h-21.092c-.908 3.633-1.917 7.266-2.826 11H951.4c5-20.4 10.7-38.5 18.2-56h20.3a340.307 340.307 0 0 1 18.1 56h-14.978c-.909-3.734-1.817-7.367-2.826-11Zm-3.696-13c-2.2-7.8-4.6-15-6.8-20.8-2.4 5.8-4.6 13.1-6.9 20.8ZM1081 128.7v.3h-16.142a81.769 81.769 0 0 0-3.733-9.047 72.862 72.862 0 0 0-4.641-7.953H1047v17h-15V73h24.702c15.93 0 21.878 9.4 21.878 18.4a17.398 17.398 0 0 1-8.469 15.4 76.517 76.517 0 0 1 6.15 10.5 118.979 118.979 0 0 1 4.739 11.4ZM1047 99h9.236c3.889 0 6.903-2.528 6.903-7a6.608 6.608 0 0 0-6.903-7H1047ZM1088 73h15v56h-15ZM1167.1 101c0 15.8-12.1 28-31.8 28H1114V73h21.3c19.7 0 31.8 12.2 31.8 28Zm-15.352 0c0-10.033-7.847-15-16.39-15H1129v30h6.358c8.543 0 16.39-4.967 16.39-15ZM1189 86v9h27v12h-26v9h28v13h-43V73h43v13h-29Z"
                transform="translate(-494 -73)"
            />
        </g>
    </svg>
)

export default MakeLifeARide;
